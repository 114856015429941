import * as coreClient from "@azure/core-client";

export const LoginRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LoginRequest",
    modelProperties: {
      username: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "username",
        required: true,
        type: {
          name: "String",
        },
      },
      password: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "password",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const UserDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UserDto",
    modelProperties: {
      userId: {
        serializedName: "userId",
        required: true,
        type: {
          name: "Number",
        },
      },
      username: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "username",
        required: true,
        type: {
          name: "String",
        },
      },
      firstName: {
        serializedName: "firstName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      lastName: {
        serializedName: "lastName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      siteId: {
        serializedName: "siteId",
        required: true,
        type: {
          name: "Number",
        },
      },
      sites: {
        serializedName: "sites",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SiteDto",
            },
          },
        },
      },
    },
  },
};

export const SiteDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SiteDto",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      url: {
        serializedName: "url",
        required: true,
        type: {
          name: "String",
        },
      },
      copyright: {
        serializedName: "copyright",
        type: {
          name: "String",
        },
      },
      footer: {
        serializedName: "footer",
        type: {
          name: "String",
        },
      },
      layout: {
        serializedName: "layout",
        type: {
          name: "Composite",
          className: "Layout",
        },
      },
    },
  },
};

export const Layout: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Layout",
    modelProperties: {
      menu: {
        serializedName: "menu",
        type: {
          name: "String",
        },
      },
      preMods: {
        serializedName: "preMods",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const FileMeta: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "FileMeta",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      uri: {
        serializedName: "uri",
        type: {
          name: "String",
        },
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "DateTime",
        },
      },
      default: {
        serializedName: "default",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const BlobUploadToken: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "BlobUploadToken",
    modelProperties: {
      uri: {
        serializedName: "uri",
        required: true,
        type: {
          name: "String",
        },
      },
      sasToken: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "sasToken",
        required: true,
        type: {
          name: "String",
        },
      },
      blobUri: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "blobUri",
        required: true,
        type: {
          name: "String",
        },
      },
      container: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "container",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ThemeSpec: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ThemeSpec",
    modelProperties: {
      version: {
        serializedName: "version",
        required: true,
        type: {
          name: "String",
        },
      },
      groups: {
        serializedName: "groups",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "GroupSpec",
            },
          },
        },
      },
      components: {
        serializedName: "components",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ComponentSpec",
            },
          },
        },
      },
    },
  },
};

export const GroupSpec: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GroupSpec",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      icon: {
        serializedName: "icon",
        nullable: true,
        type: {
          name: "String",
        },
      },
      components: {
        serializedName: "components",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const ComponentSpec: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ComponentSpec",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      tokens: {
        serializedName: "tokens",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TokenSpec",
            },
          },
        },
      },
    },
  },
};

export const TokenSpec: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TokenSpec",
    modelProperties: {
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "String",
        },
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        nullable: true,
        type: {
          name: "String",
        },
      },
      options: {
        serializedName: "options",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Option",
            },
          },
        },
      },
      isRequired: {
        serializedName: "isRequired",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      value: {
        serializedName: "value",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Option: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Option",
    modelProperties: {
      key: {
        serializedName: "key",
        required: true,
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ThemeVersionDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ThemeVersionDto",
    modelProperties: {
      siteId: {
        serializedName: "siteId",
        required: true,
        type: {
          name: "Number",
        },
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      status: {
        serializedName: "status",
        required: true,
        type: {
          name: "String",
        },
      },
      name: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      createdAt: {
        serializedName: "createdAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      publishedAt: {
        serializedName: "publishedAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      theme: {
        serializedName: "theme",
        type: {
          name: "Composite",
          className: "ThemeDto",
        },
      },
    },
  },
};

export const ThemeDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ThemeDto",
    modelProperties: {
      specVersion: {
        serializedName: "specVersion",
        required: true,
        type: {
          name: "String",
        },
      },
      components: {
        serializedName: "components",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ComponentDto",
            },
          },
        },
      },
    },
  },
};

export const ComponentDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ComponentDto",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      tokens: {
        serializedName: "tokens",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TokenDto",
            },
          },
        },
      },
    },
  },
};

export const TokenDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TokenDto",
    modelProperties: {
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "String",
        },
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ProblemDetails: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ProblemDetails",
    additionalProperties: { type: { name: "Object" } },
    modelProperties: {
      type: {
        serializedName: "type",
        nullable: true,
        type: {
          name: "String",
        },
      },
      title: {
        serializedName: "title",
        nullable: true,
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      detail: {
        serializedName: "detail",
        nullable: true,
        type: {
          name: "String",
        },
      },
      instance: {
        serializedName: "instance",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CreateThemeReqDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateThemeReqDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      theme: {
        serializedName: "theme",
        type: {
          name: "Composite",
          className: "ThemeDto",
        },
      },
    },
  },
};

export const PublishReqDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PublishReqDto",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      versionId: {
        serializedName: "versionId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
    },
  },
};
