import { action, observable, runInAction } from "mobx"
import { IDisposer } from "mobx-utils"

import { ComponentSpec, DesignerClient, ThemeDto, ThemeSpec, ThemeVersionDto, TokenDto, TokenSpec } from "@/client"
import { createUseStore } from "./util"
import { WebFont } from "@/app/lib/typography/WebFont"

export class ThemaStore {
    designer: DesignerClient
    @observable accessor css: string = ''

    @observable accessor theme: Theme | null = null

    disposer?: IDisposer

    constructor(designer: DesignerClient) {
        this.designer = designer
    }

    async load() {
        const specProm = this.designer.themeSpecGet()
        const draftProm = this.designer.themeDraftGet()

        const [spec, draft] = await Promise.all([specProm, draftProm])

        runInAction(() => {
            console.log("Update from", draft)
            const theme = Theme.FromSpec(spec)
            theme.updateFromDto(draft)
            this.theme = theme
            this.render()
        })
    }

    async restore(id: string) {
        const version = await this.designer.themeGet(id)
        await this.designer.themeDraftCreate({theme: version.theme!, name: version.name})
        runInAction(() => {
            console.log("Update from", version)
            this.theme?.updateFromDto(version)
            this.render()
        })
    }

    groups() {
        const groups = this.theme?.groups.map(g => this.group(g.id)!) ?? []
        return groups
    }

    group(id: string): Nullable<Group> {
        const groupSpec = this.theme?.groups.find(g => g.id == id)
        if (!groupSpec) return null
        const components =
            groupSpec?.components
                .map(id => this.theme?.components
                    .find(c => c.id == id))
                .filter(i => !!i)
                ?? []

        return {
            ...groupSpec,
            components
        }
    }

    component(id: string): Nullable<Component> {
        const component = this.theme?.components.find(c => c.id == id)
        if (!component) return null
        else return component
    }

    update() {
        if (!this.theme) return
        this.render()
        this.designer.themeDraftCreate({theme: this.theme})
    }

    @action render() {
        const renderer = new ThemeRenderer
        const css = renderer.render(this.theme!)
        this.css = css
    }

    save = async (name?: string) => {
        if (!this.theme) return
        name ??= "My Theme"
        if (!this.theme) return
        await this.designer.themeDraftSave(name)
    }

    publish = async ({name, versionId}: {name?: string, versionId?: string} = {}) => {
        name ??= "My Published Version"
        await this.designer.themePublish({name, versionId})
    }
}
export const [ThemaCtx, useThema] = createUseStore(ThemaStore)


export class ThemeRenderer {
    prefix = "hr-"
    defaultSelector = `:root[data-theme="designer"]`
    constructor() {}

    render(theme: Theme): string {
        let output = ""

        const fontFams = theme.components
            .map(c => c.tokens.filter(t => t.type == TokenType.FontFammily))
            .flat()
            .map(t => t.value)

        const fonts: string[] = []

        for (const fam of fontFams) {
            if (!fam) continue
            const webFont = WebFont.FromFamily(fam)
            if (!webFont) continue
            fonts.push(webFont.familyQuery)
        }

        output += `@import url("https://fonts.googleapis.com/css2?family=${fonts.join("&family=")}&display=swap");\n\n`

        output += "@layer override {\n"
        for (const comp of theme.components) {
            output += `${this.defaultSelector} {\n`
            for (const token of comp.tokens) {
                if (!token.value) continue
                if (token.type == TokenType.Image)
                    output += `--${this.prefix}${token.id}: url("${token.value}");\n`
                else if (token.type == TokenType.FontFammily) {
                    const webFont = WebFont.FromFamily(token.value)
                    if (!webFont) continue
                    output += `--${this.prefix}${token.id}: ${webFont.fontFamily};\n`
                }
                else
                    output += `--${this.prefix}${token.id}: ${token.value};\n`
            }
            output += "}\n"
        }
        output += "}\n"

        return output
    }

}

interface Group {
    id: string
    name: string
    icon?: string
    components: Component[]
}

export enum TokenType {
    Color = "Color",
    Image = "Image",
    String = "String",
    Number = "Number",
    FontFammily = "FontFamily",
    Select = "Select"
}

interface GroupSpec {
    id: string
    name: string
    icon?: string
    components: string[]
}

class Theme {
    @observable accessor name!: string | null
    @observable accessor specVersion!: string
    @observable accessor components: Component[] = []
    @observable accessor groups: GroupSpec[] = []

    static FromSpec(spec: ThemeSpec) {
        const theme = new Theme
        theme.specVersion = spec.version
        theme.components = spec.components.map(c => Component.FromSpec(c))
        theme.groups = spec.groups
        return theme
    }

    updateFromDto(dto: ThemeVersionDto) {
        const {theme, name} = dto
        this.name = name
        for (const dtoComponent of theme.components) {
            const component = this.component(dtoComponent.id)
            if (!component) continue

            console.log(component)

            for (const dtoToken of dtoComponent.tokens) {
                const token = component.tokens.find(t => t.id == dtoToken.id)
                if (!token) continue
                token.value = dtoToken.value
            }
        }
    }

    component(id: string): Nullable<Component> {
        const component = this.components.find(c => c.id == id)
        return component ?? null
    }
}

class Component {
    id!: string
    name!: string
    tokens!: Token[]

    static FromSpec(spec: ComponentSpec) {
        const comp = new Component
        comp.id = spec.id
        comp.name = spec.name
        comp.tokens = spec.tokens.map(t => Token.FromSpec(t))
        return comp
    }

    token(id: string): Nullable<Token> {
        const token = this.tokens.find(t => t.id == id)
        return token ?? null
    }
}

interface Option {
    key: string
    value: string
}

class Token {
    type!: TokenType
    id!: string
    name?: string
    description?: string
    options!: Array<Option>
    required?: boolean
    @observable accessor value: string | null = null

    static FromSpec(spec: TokenSpec) {
        const token = new Token
        token.type = spec.type as TokenType
        token.id = spec.id
        token.name = spec.name
        token.description = spec.description
        token.options = spec.options
        return token
    }

    fromDto(dto: TokenDto) {
        this.type = dto.type as TokenType
        this.id = dto.id
        this.value = dto.value ?? null
    }
}